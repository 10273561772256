<template>
    <v-dialog persistent v-model="show" width="250">
        <v-card class="text-xs-center">
            <v-card-text class="title" v-html="msg">
            </v-card-text>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-text-field outline clearable name="phone" :label="$t('receiverDialog.phone-number')" type="tel"
                                    v-model="phone" mask="##########" :rules="phoneRules" required>
                    </v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn block flat v-if="secondaryBtn" class="subheading" color="primary" @click="clickSecondary">{{secondaryBtn}}</v-btn>
                <v-btn block v-if="primaryBtn" class="subheading" color="primary" :disabled="btnDisabled" @click="clickPrimary">{{primaryBtn}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import store from '@/store'
import i18n from '@/i18n'

export default {
    name: 'receiver-dialog',
    props: {
        show: {
            type: Boolean,
            required: true
        },
        msg: {
            type: String,
            required: true
        },
        primaryBtn: {
            type: String,
            default: '關閉'
        },
        secondaryBtn: {
            type: String,
            default: null
        },
        receiverPhone: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            phoneRules: [
                v => !!v || i18n.t('receiverDialog.emptyErr'),
                v => /^09\d{8}$/.test(v) || i18n.t('receiverDialog.validErr'),
                v => (v !== store.getters.user.phone) || i18n.t('receiverDialog.not-set-repeatedly')
            ],
            valid: false
        }
    },
    computed: {
        phone: {
            get() {
                return this.receiverPhone
            },
            set(val) {
                this.$emit('update:receiverPhone',val)
            }
        },
        btnDisabled () {
            return !this.valid
        }
    },
    methods: {
        clickSecondary() {
            this.$emit('update:show', false)
            this.$emit('secondary')
        },
        clickPrimary() {
            this.$refs.form.reset()
            this.$emit('update:show', false)
            this.$emit('primary')
        }
    }
}
</script>

<style scoped>
</style>
