<script>
import { ElectronicMoneyType } from '@/components/payments/ElectronicMoney'
export default {
    data() {
        return {
            payinfo: null
        }
    },
    computed: {
        isPayByElectronicMoney() {
            return this.payinfo !== null && this.payinfo.type === ElectronicMoneyType && this.payinfo['detail']['trade_money'] !== 0
        }
    }
}
</script>