<script>
export default {
    data() {
        return {
            countdown: 0,
            countdownInterval: null
        }
    },
    methods: {
        startCountdown(duration, timesupFunc) {
            this.stopCountdown()
            if (duration > 0) {
                this.countdown = duration
                var vm = this
                this.countdownInterval = setInterval(() => {
                    if (vm.countdown > 0) vm.countdown -= 1
                    else {
                        timesupFunc()
                        vm.stopCountdown()
                    }
                }, 1000)
            }
        },
        stopCountdown() {
            clearInterval(this.countdownInterval)
            this.countdownInterval = null
        }
    },
    deactivated() {
        this.stopCountdown()
    }
}
</script>