<script>
export default {
    data() {
        return {
            receiverDialog: {
                show: false,
                msg: '',
                primaryBtn: '關閉',
                secondaryBtn: null,
                inputBox: false
            }
        }
    },
    methods: {
        showRecevierDialog(msg, primaryBtn, secondaryBtn='', inputBox=false) {
            this.receiverDialog.msg = msg
            this.receiverDialog.primaryBtn = primaryBtn
            this.receiverDialog.secondaryBtn = secondaryBtn
            this.receiverDialog.show = true
            this.receiverDialog.inputBox = inputBox
        },
        closeReceiverDialog() {
            this.receiverDialog.show = false
        }
    }
}
</script>
