<template>
    <v-card flat>
        <v-subheader class="subheading" :hidden="hiddenTitle">
            {{ payRulesTitle() }}
        </v-subheader>
        <v-list>
            <v-list-group
                v-for="payment in filteredPayments"
                :key="payment.key"
                v-model="payment.active"
                :prepend-icon="payment.action"
            >
                <template v-slot:activator>
                    <v-list-tile>
                        <v-list-tile-content>
                            <v-list-tile-title>
                                {{ payment.title }}
                                <v-icon v-show="payment.active" color="cyan">check_circle</v-icon>
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </template>
                <direct-pay
                    v-if="payment.key === 'creditcard'"
                    :enable="payment.active"
                    :message="payment.msg"
                    @payinfo="updatePayInfo"
                    ref="directPay"
                ></direct-pay>
                <electronic-money
                    v-if="payment.key === 'electronicmoney'"
                    :enable="payment.active"
                    :message="payment.msg"
                    :trade.sync="startTrade"
                    :amount="amount"
                    :doorId="doorId"
                    :transType="transType"
                    :action="action"
                    @payinfo="updatePayInfo"
                ></electronic-money>
                <line-pay
                    v-if="payment.key === 'linepay'"
                    :enable="payment.active"
                    :message="payment.msg"
                    @payinfo="updatePayInfo"
                ></line-pay>
                <JKOPay
                    v-if="payment.key === 'jkopay'"
                    :enable="payment.active"
                    :message="payment.msg"
                    @payinfo="updatePayInfo"
                ></JKOPay>
            </v-list-group>
        </v-list>
        <v-layout justify-center>
            <v-btn flat large block color="primary" @click="back">
                <v-icon>arrow_back_ios</v-icon>{{ $t('paymentSelector.back') }}
            </v-btn>
            <v-btn large block class="subheading" color="primary" :disabled="!isValid" @click="submit">{{ submitMsg() }}</v-btn>
        </v-layout>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
        <message-dialog
            :show.sync="msgDialog.show"
            :msg="msgDialog.msg"
            :primary-btn="msgDialog.primaryBtn"
        ></message-dialog>
    </v-card>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import DirectPay from '@/components/payments/DirectPay'
import ElectronicMoney from '@/components/payments/ElectronicMoney'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import MessageDialog from '@/components/MessageDialog'
import MessageDialogControl from '@/mixins/MessageDialogControl'
import { ElectronicMoneyType } from '@/components/payments/ElectronicMoney'
import { getAxiosConfig } from '@/utils/AuthService'
import { HOMETYPES } from '@/utils/utils'
import { HostUrl } from '@/store'
import i18n from '@/i18n'
import LinePay from '@/components/payments/LinePay'
import JKOPay from '@/components/payments/JKOPay'

const CREDIT_CARD = {
    key: 'creditcard',
    index: 0
}
const ELECTRONIC_MONEY = {
    key: 'electronicmoney',
    index: 1
}
const LINE_PAY = {
    key: 'linepay',
    index: 2
}
const JKO_PAY = {
    key: 'jkopay',
    index: 3
}

export default {
    name: 'payment-selector',
    components: { DirectPay, ElectronicMoney, MessageDialog, WaitingDialog, LinePay, JKOPay },
    mixins: [ MessageDialogControl, WaitDialogControl ],
    props: {
        enable: {
            type: Boolean,
            required: true
        },
        amount: {
            type: Number,
            required: true
        },
        doorId: {
            type: [Number, Array],
            required: true
        },
        action: {
            type: Number,
            required: true
        },
        transType: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            payments: [
                { key: CREDIT_CARD.key, title: i18n.t('paymentSelector.credit-card'), active: false, enable: true, msg: '', action: 'credit_card' },
                { key: ELECTRONIC_MONEY.key, title: i18n.t('paymentSelector.easy-card'), active: false, enable: true, msg: '', action: 'contactless' },
                { key: LINE_PAY.key, title: 'Line Pay', active: false, enable: true, msg: '', action: 'exit_to_app' },
                { key: JKO_PAY.key, title: i18n.t('paymentSelector.jkopay'), active: false, enable: true, msg: '', action: 'store' }
            ],
            payinfo: null,
            startTrade: false,
            hiddenTitle: false,
            zeroPay: false,
            submitMark: false
        }
    },
    watch: {
        enable() {
            if (this.enable) this.getPaymentsStatus()
        }
    },
    computed: {
        isValid() {
            if (this.zeroPay && this.payinfo == null)
                return true
            else
                return this.payinfo !== null
        },
        filteredPayments() {
            let validPayments = []
            if (this.payments)
                validPayments = this.payments.filter(p => p.enable)
            if (validPayments.length === 1) validPayments[0].active = true
            return validPayments
        }
    },
    methods: {
        getPaymentsStatus() {
            this.showDialog(i18n.t('paymentSelector.get-pay-method'), i18n.t('paymentSelector.fail-get-method'))
            let url = HostUrl + '/api/box/' + store.getters.targetBoxId + '/payments/status'
            var vm = this
            let zeroValidPayment = false
            axios.get(url, getAxiosConfig())
                .then(function (response) {
                    if (response.data.code == 0) {
                        let status = response.data.payments
                        //let status = {
                        //    DIRECT: {enable: false},
                        //    ELECTRONIC_MONEY: {'EASYCARD': {enable: false}, IPASS: {enable: false}},
                        //    JKO_PAY: {enable: false},
                        //    LINE_PAY: {enable: false}
                        //}
                        let el_mark = null
                        for (const [key, values] of Object.entries(status)) {
                            let index = -1
                            switch (key) {
                                case 'DIRECT':
                                    index = CREDIT_CARD.index
                                    break
                                case 'LINE_PAY':
                                    // TODO
                                    index = LINE_PAY.index
                                    break
                                case 'JKO_PAY':
                                    index = JKO_PAY.index
                                    break
                                case 'ELECTRONIC_MONEY':
                                    index = ELECTRONIC_MONEY.index
                                    break
                                default:
                                    console.log('unsupport payment type: ' + key)
                                    break
                            }
                            vm.zeroPay = false
                            if (index >= 0) {
                                if (key === 'ELECTRONIC_MONEY') {
                                    el_mark = (values.IPASS.enable || values.EASYCARD.enable)
                                    vm.payments[index].enable = el_mark

                                    
                                    //更改 title 的時候會出現問題，暫時註解掉
                                    if (values.EASYCARD.enable === true && values.IPASS.enable === false) {
                                        vm.payments[index].title = i18n.t('paymentSelector.easy-card')
                                    } else if (values.EASYCARD.enable === false && values.IPASS.enable === true) {
                                        vm.payments[index].title = i18n.t('paymentSelector.ipass')
                                    } else {
                                        vm.payments[index].title = i18n.t('paymentSelector.easy-card-ipass')
                                    }

                                    if (vm.amount == 0 && store.getters.pageHome == HOMETYPES.LOCKER) {
                                        vm.submitMark = true
                                        store.commit('setElectronicMoneyZeroMark', el_mark)
                                    } else
                                        store.commit('setElectronicMoneyZeroMark', null)
                                } else if (vm.amount == 0) { // 0元 方案
                                    if (values.enable === true) zeroValidPayment = true
                                    vm.payments[index].enable = false
                                    vm.zeroPay = true
                                } else {
                                    vm.payments[index].enable = values.enable
                                    vm.payments[index].msg = values.message
                                }
                            }
                        }
                        //支付方式全部關閉時，且該儲格價格 != 0，跳出提醒視窗
                        let validPayments = vm.payments.filter(p => p.enable)
                        if (zeroValidPayment != true && vm.zeroPay === true) {
                            vm.zeroPay = false
                            store.commit('setElectronicMoneyZeroMark', null)
                        }
                        if (validPayments.length === 0 && vm.zeroPay === false) vm.$emit('update:emptyDialog', true)
                    } else {
                        store.commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                    }
                })
                .catch(function (err) {
                    console.log('error', err)
                    store.commit('setSnackBar', {
                        message: i18n.t('paymentSelector.fail-get-later'),
                        color: 'error'
                    })
                })
                .finally(function() { vm.closeDialog() })
        },
        updatePayInfo(payinfo) {
            this.payinfo = payinfo
            console.log('payinfo', payinfo)
            if (payinfo === null && this.amount === 0 && this.submitMark) {
                this.payments = null
                this.submit()
            }
            console.log('ElectronicMoneyType::'+ ElectronicMoneyType)
            if (this.payinfo && this.payinfo.hasOwnProperty('type') && this.payinfo.type === ElectronicMoneyType) {
                console.log('ready pay')
                console.log('this.payinfo.hasOwnProperty', this.payinfo.hasOwnProperty('type'))
                console.log('this.payinfo.type', this.payinfo.type)
                this.$emit('pay', this.payinfo)
            }
            // if (this.payinfo && this.payinfo.hasOwnProperty('type') && this.payinfo.type === ElectronicMoneyType) this.$emit('pay', this.payinfo)
        },
        payRulesTitle() {
            if (this.zeroPay) {
                this.hiddenTitle = true
                return ''
            }
            else {
                this.hiddenTitle = false
                return i18n.t('paymentSelector.select-method')
            }
        },
        back() {
            this.$emit('back')
        },
        submitMsg() {
            if (this.zeroPay) {
                return i18n.t('paymentSelector.continue')
            } else {
                return i18n.t('paymentSelector.pay')
            }
        },
        submit() {
            let payment = null
            if (this.payments)
                payment = this.payments.find((p) => {
                    return p.active
                })
            //console.log('payment', payment)
            if (this.zeroPay && payment == null && this.submitMark) {
                this.$emit('pay', null)
                return
            }
            console.log('payment.key', payment.key)
            switch (payment.key) {
                case 'electronicmoney':
                    this.startTrade = true
                    break
                case 'creditcard':
                    this.$emit('pay', this.payinfo)
                    break
                case 'linepay':
                    this.$emit('pay', this.payinfo)
                    break
                case 'jkopay':
                    this.$emit('pay', this.payinfo)
                    break
                default:
                    this.showMsgDialog(i18n.t('paymentSelector.select-pay-method'), i18n.t('paymentSelector.close'))
                    break
            }
        }
    }
}
</script>