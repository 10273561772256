<template>
    <v-card flat>
        <v-card-text class="pa-0">
            <v-subheader class="subheading" v-if="!rulesHidden()">
                {{ $t('paymentInfo.payment-content') }}&nbsp;
                <v-chip v-show="countdownMsg" small dark color="info">
                    {{ countdownMsg }}
                </v-chip>
            </v-subheader>
            <v-list subheader class="pb-3">
                <v-list-tile
                    v-for="(item, index) in details"
                    :key="index"
                    style="height: 25px;"
                >
                    <v-list-tile-content>
                        <v-list-tile-title :class="{ bold: (item.key == $t('paymentInfo.cell-number')) }">{{ item.key }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action :class="{ bold: (item.key == $t('paymentInfo.cell-number')) }">
                        {{ item.value }}
                    </v-list-tile-action>
                </v-list-tile>
            </v-list>
            <p v-if="note" class="pl-3 pr-2">
                <span class="subheading">{{ $t('paymentInfo.note') }}</span><br>
                <span class="body-1 grey--text">{{note}}</span>
            </p>
        </v-card-text>
        <v-expansion-panel popout v-if="rules">
            <v-expansion-panel-content class="cyan lighten-5" :hidden="rulesHidden">
                <template v-slot:header>
                    <div class="subheading">{{ $t('paymentInfo.storage-precaution') }}</div>
                </template>
                <terms-of-use back-color="cyan lighten-5"></terms-of-use>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-divider v-else></v-divider>
        <message-dialog
            :show.sync="msgDialog.show"
            :msg="msgDialog.msg"
            :primary-btn="msgDialog.primaryBtn"
            @primary="triggerCountdown"
        ></message-dialog>
    </v-card>
</template>

<script>
import MessageDialog from '@/components/MessageDialog'
import TermsOfUse from '@/components/TermsOfUse'
import MessageDialogControl from '@/mixins/MessageDialogControl'
import CountdownMixin from '@/mixins/CountdownMixin'
import { convertSecToTimeStr } from '@/utils/utils.js'
import i18n from '@/i18n'

export default {
    name: 'payment-info',
    components: { MessageDialog, TermsOfUse },
    mixins: [ CountdownMixin, MessageDialogControl ],
    props: {
        enable: {
            type: Boolean,
            required: true
        },
        amount: {
            type: Number,
            required: true
        },
        boxId: {
            type: String,
            required: true
        },
        doorId: {
            type: [Number, Array],
            required: true
        },
        note: {
            type: String,
            default: ''
        },
        rules: {
            type: Boolean,
            default: false
        },
        period: {
            type: Number,
            default: 0  // 0 表示無有效期, 也就是不會更新價格; > 0 單位為秒數, 表示價格有效期時間長度, 超過此時間會告知 Parent 要更新價格
        }
    },
    data() {
        return {
            countdownMsg: ''
        }
    },
    watch: {
        enable() {
            if (this.enable) this.triggerCountdown()
            else this.stopCountdown()
        },
        countdown() {
            if (this.countdown <= 0) this.countdownMsg = ''
            else this.countdownMsg = i18n.t('paymentInfo.update-amount-time') + convertSecToTimeStr(this.countdown)
        }
    },
    computed: {
        details() {
            return [
                { key: i18n.t('paymentInfo.transaction-amount'), value: this.amount + i18n.t('paymentInfo.NTD') },
                { key: i18n.t('paymentInfo.cabinet-number'), value: this.boxId },
                { key: i18n.t('paymentInfo.cell-number'), value: this.showDoorId }
            ]
        },
        showDoorId() {
            if (typeof(this.doorId) === 'number') return this.doorId
            else return Array.prototype.slice.call(this.doorId).join(', ')
        }
    },
    methods: {
        triggerCountdown() {
            this.closeMsgDialog()
            this.$emit('refresh') // refresh packages information
            if (this.period > 0) {
                this.startCountdown(this.period, this.refresh)
            }
        },
        refresh() {
            this.showMsgDialog(i18n.t('paymentInfo.expired'), i18n.t('paymentInfo.confirm'))
            this.stopCountdown()
        },
        rulesHidden() {
            if (this.amount == 0) return true
            else return false
        }
    }
}
</script>

<style scoped>
    .bold {
        font-weight: bold;
        font-size: 1.25rem;
        color: red;
    }
</style>
