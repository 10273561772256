<template>
    <v-container class="pa-0">
        <v-subheader class="subheading">{{ $t('termsOfUse.storage-rule') }}</v-subheader>
        <v-card flat subheader>
            <v-card-text class="pt-0 pb-0" :class="backColor" v-show="!home">
                <ul v-for="(item, index) in keepingRules" :key="index">
                    <li>{{ item }}</li>
                </ul>
            </v-card-text>
            <v-card-text class="pt-0 pb-0" :class="backColor" v-show="home">
                <ul v-for="(item, index) in keepingRulesLion" :key="index">
                    <li>{{ item }}</li>
                </ul>
            </v-card-text>
        </v-card>
        <v-subheader class="subheading">{{ $t('termsOfUse.other-precaution') }}</v-subheader>
        <v-card flat subheader>
            <v-card-text class="pt-0 pb-0" :class="backColor" v-show="!home">
                <ul v-for="(item, index) in warnings" :key="index">
                    <li>{{ item }}</li>
                </ul>
            </v-card-text>
            <v-card-text class="pt-0 pb-0" :class="backColor" v-show="home">
                <ul v-for="(item, index) in warningsLion" :key="index">
                    <li>{{ item }}</li>
                </ul>
            </v-card-text>
            <v-card-text>
                <br>
                <p>{{ $t('termsOfUse.please-contact') }}</p>
                <p style="text-align:right">
                    {{ $t('termsOfUse.wan-chou-tong') }}<br>
                    {{ $t('termsOfUse.service-hotline') }} : <a href="tel:+886 2 27253996">02-2725-3996</a> {{ $t('termsOfUse.hours') }}
                </p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import store from '@/store'
import { HOMETYPES } from '@/utils/utils'
import i18n from '@/i18n'

export default {
    name: 'terms-of-use',
    props: {
        backColor: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            home: store.getters.pageHome === HOMETYPES.TRAVELDOCS,
            keepingRules: [
                i18n.t('termsOfUse.temporary-only-once'),
                i18n.t('termsOfUse.month-unlimited'),
                i18n.t('termsOfUse.temporary-or-month-exceed'),
                i18n.t('termsOfUse.qr-code-expire'),
                i18n.t('termsOfUse.item-more-30-day')
            ],
            warnings: [
                i18n.t('termsOfUse.storage-only'),
                i18n.t('termsOfUse.prohibit-place'),
                i18n.t('termsOfUse.user-damage'),
                i18n.t('termsOfUse.reserve-right'),
                i18n.t('termsOfUse.limit-liability')
            ],
            keepingRulesLion: [
                "置物櫃每次使用限開關 1 次 (QR Code 限用 1 次)。",
                "如物品放置超過取件時間，雄獅會將物品取出移至門市。",
                "若 QR Code 因逾期失效無法開啟，旅客須電洽置物櫃管理單位取物。"
            ],
            warningsLion: [
                "本置物櫃僅供物品寄放，不負任何保管責任，現金有價證券之貴重物品請勿放入置物櫃內。",
                "嚴禁寄放易腐、易燃及任何危險物品。",
                "使用者如破壞櫃身或其他櫃內物品，須負賠償責任。",
                "置物櫃管理單位必要時，得將旅客寄存物品取出檢查再放入。"
            ]
        }
    }
}
</script>